import { createTheme } from "@mui/material/styles";
import { colors, typography } from "../../shared/styleConfig";

export { colors, typography };

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.red,
    },
    background: {
      default: colors.black,
    },
    error: {
      main: colors.red,
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    ...typography,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "0.85rem",
          letterSpacing: "0.1rem",
          backgroundColor: colors.white,
          color: colors.black,
          borderRadius: "0.25rem",
          border: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.navy,
          color: colors.white,
          borderRadius: "2px",
          padding: "10px 20px",
          fontSize: "1rem",
          fontWeight: 700,
          letterSpacing: "0.02857em",
          textTransform: "uppercase",
          "&:hover": {
            color: colors.gold,
            backgroundColor: colors.darkNavy,
          },
        },
      },
      variants: [
        {
          props: { variant: "secondary" },
          style: {
            fontSize: "0.85rem",
            letterSpacing: "0.1rem",
            textTransform: "uppercase",
            padding: "10px 20px",
            border: `1px solid ${colors.white}`,
            borderRadius: "0.25rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
            fontWeight: 700,
            color: colors.white,
            boxShadow: "none",

            "&:disabled": {
              backgroundColor: "#111",
              color: colors.lightGray,
              borderColor: colors.lightGray,
            },
            "&:hover": {
              background: "none",
              borderColor: colors.red,
              color: colors.gold,
            },
          },
        },
        {
          props: { variant: "emphasized" },
          style: {
            backgroundColor: colors.gold,
            color: colors.black,
            border: `1px solid ${colors.darkGold}`,

            "&:disabled": {
              backgroundColor: "#111",
              color: colors.lightGray,
              borderColor: colors.lightGray,
            },
            "&:hover": {
              background: colors.darkGold,
              color: colors.black,
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: colors.black,
            backgroundColor: "transparent",
            border: "none",
            textTransform: "none",
            fontWeight: 400,

            "&:disabled": {
              backgroundColor: "#111",
              color: colors.lightGray,
              borderColor: colors.lightGray,
            },
            "&:hover": {
              background: "none",
              color: colors.gold,
            },
          },
        }
      ],
    },
  },
});
