export const colors = {
  blue: "#19232d",
  gold: "#efc067",
  darkGold: "#f3d08f",
  white: "#fff",
  black: "#000",
  navy: "#19232d",
  darkNavy: "#101820",
  gray: "#3b3b3f",
  lightGray: "#666",
  lighterGray: "#ccc",
  red: "#f0592b",
  pink: "#ba574b",
  brown: "#5a400e",
  lightYellow: "#F5EAD6",
  orange: "#bf722c",
};

export const typography = {
  h1: {
    marginTop: "0px",
    fontSize: "3.5rem",
    lineHeight: "1.1em",
    fontWeight: 800,
    letterSpacing: "-0.25rem",
    "@media (min-width: 768px)": {
      fontSize: "6.5rem",
      lineHeight: "1em",
      letterSpacing: "-0.5rem",
    },
  },
  h2: {
    color: colors.gold,
    fontSize: "2.5rem",
    fontWeight: 700,
    height: "auto",
    lineHeight: "1.2",
    "@media (min-width: 768px)": {
      fontSize: "1.5rem",
      height: "1.5rem",
      lineHeight: "24px",
    },
  },
  h3: {
    marginTop: "0px",
    fontSize: "1.75rem",
    lineHeight: "1.2em",
    fontWeight: 700,
    letterSpacing: "-1px",
    "@media (min-width: 768px)": {
      fontSize: "2rem",
      lineHeight: "1.15em",
      letterSpacing: "-2px",
    },
  },
  body1: {
    fontSize: "1rem",
    letterSpacing: "normal",
  }
};
